<script>
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import { required } from "vuelidate/lib/validators";
import axios from 'axios';
import Swal from "sweetalert2";

/**
 * transaction Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Profile",
      fullname:"",
      useremail:"",
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      submitted: false,
      keyup : false,
      isLoading: false,
      showPassword1 : false,
      showPassword2 : false,
      showPassword3 : false,
      name: '',
      email: '',
      role: '',
      path: '',
      img: '',
      scan: true,
      use: false,
    };
  },
  validations: {
    oldPassword: { required },
    newPassword: { required },
    confirmNewPassword: { required },
  },
  methods: {
    User(){
      let id  = JSON.parse(localStorage.getItem("user")); 
      this.name = id.first_name + ' ' + id.last_name;
      this.email = id.user_email;
      this.fullname = this.name.toUpperCase();
      this.useremail = this.email.toUpperCase();
    },
    submit() {
        let idUser = JSON.parse(localStorage.getItem("user")).user_id;
        let admin = JSON.parse(localStorage.getItem("user")).id;
        let data = { 
            old_password: this.oldPassword,
            new_password: this.newPassword,
            admin : admin,
        }
        axios({
            method: "PUT",
            url: `/v1/changepassword/${idUser}`,
            data: data,
        })
        .then((res) => {
            if(res.data.success){
                Swal.fire("Success", res.data.message, "success");
                this.$router.push('/')
            } else {
                Swal.fire("Error", res.data.message, "error");
            }
        })
    },
    validat(){
        this.keyup = true
    },
    qrcode(){
      let id  = JSON.parse(localStorage.getItem("user")).id;
      let data = {
        name : this.name,
        email : this.email,
        id: id
      }
      axios.post(`/v1/qr-codes`, data)
      .then((res) => {
        this.path = res.data.secret_key
        this.img = "data:image/svg+xml;base64," + this.path
      })
    },
    Scan(){
      let id  = JSON.parse(localStorage.getItem("user")).id;
      let data ={
        email : this.email,
        id : id
      }
      axios.post(`/v1/scanneds`, data)
      .then((res) => {
        this.scan = res.data.data[0].scan
      })
    },
    SetOTP(value){
      let id  = JSON.parse(localStorage.getItem("user")).id; 
      let data = {
        email : this.email,
        otp : value,
        id : id
      }
      axios.post(`/v1/sets-otp`, data)
      .then((res) => {
      if(res.data.success){
            Swal.fire("Success", 'Two-Factor authentication activated!', "success");
            this.$router.push('/')
        } else {
            Swal.fire("Error", 'Wrong OTP!', "error");
        }
      })
    },
    vali(){
      this.use = true;
      if(!this.scan){
          this.qrcode();
        }
    },
    validis(){
      const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
            confirmButton: "btn btn-danger ml-2",
            cancelButton: "btn btn-info"
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons
            .fire({
            title: "Are you sure to disable Two-Factor Authentication ?",
            icon: "warning",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            showCancelButton: true,
            reverseButtons: true,
            })
            .then(result => {
            let id  = JSON.parse(localStorage.getItem("user")).id; 
            let user_id  = JSON.parse(localStorage.getItem("user")).user_id; 
            let data = {
              id : id
            } 
            if (result.value) {
                axios.post(`/v1/disabled-auth/${user_id}`, data)
                .then(() => {
                  swalWithBootstrapButtons.fire(
                    "Disabled!",
                    "Two-Factor Authentication Disabled",
                    "success"
                    );
                    this.Scan();
                })
            } else {
              result.dismiss === Swal.DismissReason.cancel
            }
        });
    },
    logout(){
      localStorage.clear();
      Swal.fire("Success", 'Log Out success', "success");
      this.$router.push('/login')
    }
  },
  created () {
    this.User();
    this.Scan();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <b-tab active>
        <template #title>
          <i class="ri-user-line" style="font-size:1rem"></i>
          <span class="font-weight-bold pl-2" style="font-size:1.2rem">General</span>
        </template>
        <div class="card p-2">
          <div class="card-body p-5">
            <div class="row">
              <div class="col-md-2 align-self-center ">
                <img src="@/assets/images/user-profile.png" alt="logo" />
              </div>
              <div class="col-md-10">
                <div class="form-group row">
                  <label for="name">Name</label>
                  <input
                      id="name"
                      v-model="fullname"
                      type="text"
                      class="form-control"
                      disabled
                  />
                </div>
                <div class="form-group row">
                    <label for="email">Email</label>
                    <input
                        id="email"
                        v-model="useremail"
                        type="text"
                        class="form-control"
                        disabled
                    />
                </div>
                <div class="form-group row">
                  <button type="submit" class="btn btn-danger" @click.prevent="logout">
                    Log Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <i class="ri-lock-2-line" style="font-size:1rem"></i>
          <span class="font-weight-bold pl-2" style="font-size:1.2rem">Change Password</span>
        </template>
        <div class="card p-2">
          <div class="card-item p-4">
            <form class="form-horizontal" @submit.prevent="submit">
              <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                <label for="userpassword">Current Password</label>
                <input
                  v-model="oldPassword"
                  password-reveal
                  :type="showPassword1 ? 'text' : 'password'"
                  class="form-control"
                  id="userpassword"
                  placeholder="Your password"
                  :class="{ 'is-invalid': submitted && $v.oldPassword.$error }"
                />
                <a class="auti-custom-input-icon2" @mousedown="showPassword1 = !showPassword1" @mouseup="showPassword1 = !showPassword1" @touchstart="showPassword1 = !showPassword1" @touchend="showPassword1 = !showPassword1">
                  <i class="fas" :class="{ 'fa-eye-slash': showPassword1, 'fa-eye': !showPassword1 }"></i>
                </a>
                <div
                  v-if="submitted && !$v.oldPassword.required"
                  class="invalid-feedback"
                >Current Password is required.</div>
              </div>

              <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                <label for="userpassword1" style="color:red" v-if="newPassword !== confirmNewPassword && keyup">New Password</label>
                <label for="userpassword1" v-else>New Password</label>
                <input
                  v-model="newPassword"
                  :type="showPassword2 ? 'text' : 'password'"
                  class="form-control"
                  id="userpassword1"
                  placeholder="Enter new password"
                  :class="{ 'is-invalid': submitted && $v.newPassword.$error }"
                />
                <a class="auti-custom-input-icon2" @mousedown="showPassword2 = !showPassword2" @mouseup="showPassword2 = !showPassword2" @touchstart="showPassword2 = !showPassword2" @touchend="showPassword2 = !showPassword2">
                  <i class="fas" :class="{ 'fa-eye-slash': showPassword2, 'fa-eye': !showPassword2 }"></i>
                </a>
                <div
                  v-if="submitted && !$v.newPassword.required"
                  class="invalid-feedback"
                >New Password is required.</div>
              </div>
              
              <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                <label for="userpassword2" style="color:red" v-if="newPassword !== confirmNewPassword && keyup">Confirm New Password</label>
                <label for="userpassword2" v-else>Confirm New Password</label>
                <input
                  v-model="confirmNewPassword"
                  @keyup="validat"
                  :type="showPassword3 ? 'text' : 'password'"
                  class="form-control"
                  id="userpassword2"
                  placeholder="Confirm your new password"
                  :class="{ 'is-invalid': submitted && $v.confirmNewPassword.$error }"
                />
                <a class="auti-custom-input-icon2" @mousedown="showPassword3 = !showPassword3" @mouseup="showPassword3 = !showPassword3" @touchstart="showPassword3 = !showPassword3" @touchend="showPassword3 = !showPassword3">
                  <i class="fas" :class="{ 'fa-eye-slash': showPassword3, 'fa-eye': !showPassword3 }"></i>
                </a>
                <div
                  v-if="submitted && !$v.confirmNewPassword.required"
                  class="invalid-feedback"
                >Confirm Password is required.</div>
              </div>

              <div class="mt-4 text-center">
                <button
                  class="btn btn-primary w-md waves-effect waves-light"
                  type="submit"
                  :disabled="
                    oldPassword &&
                        newPassword &&
                        confirmNewPassword &&
                        newPassword !== confirmNewPassword
                    "
                >Submit</button>
              </div>
            </form>
          </div>
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <i class="mdi mdi-security" style="font-size:1rem"></i>
          <span class="font-weight-bold pl-2" style="font-size:1.2rem">Security</span>
        </template>
        <div class="card p-2" style="min-height:24rem">
          <div class="card-item p-4">
            <template v-if="use">
              <div class="notice text-center">
                <h3 class="text-dark fw-bolder mb-7">Authenticator Apps</h3>
                <div class="text-gray-500 fw-bold fs-6 mb-10">Using Google Authenticator, scan the QR code. It will generate a 6 digit code for you to enter below.
                  <div id="barcode" class="pt-2 text-center pb-2">
                    <img :src="this.img" alt="" style="max-width:150px" />
                  </div>
                </div>
                <form class="form-horizontal">
                  <div class="form-group auth-form-group-custom mb-4 d-flex align-items-center justify-content-center">
                    <v-otp-input
                      ref="otpInput"
                      input-classes="otp-input"
                      separator="-"
                      :num-inputs="6"
                      :should-auto-focus="true"
                      :is-input-num="true"
                      @on-complete="SetOTP"
                    />
                    <!-- <input type="number"  v-model="otp" class="form-control form-control-lg form-control-solid text-center" placeholder="Enter authentication code" name="code" /> -->
                  </div>
                  <!-- <div class="mt-2">
                    <button type="submit" class="btn btn-primary" @click.prevent="SetOTP" :disabled="!otp">
                      Submit
                    </button>
                  </div> -->
                </form>
              </div>
            </template>
            <template v-else>
              <div class="notices">
                <span v-if="this.scan" class="align-self-center mr-3"><img src="@/assets/images/secure.svg" alt="logo" style="width:3rem;filter: invert(88%) sepia(21%) saturate(935%) hue-rotate(123deg) brightness(85%) contrast(97%);" /></span>
                <span v-else class="align-self-center mr-3"><img src="@/assets/images/secure.svg" alt="logo" style="width:3rem" /></span>
                <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                  <div class="mb-3 mb-md-0">
                    <h4 class="text-gray-900">Secure Your Account</h4>
                    <div class="text-gray-700">Two-factor authentication adds an extra layer of security to your account. To log in, in addition you'll need to provide a 6 digit code</div>
                    <span class="text-success" v-if="this.scan">Your account has activated Two-factor authentication</span>
                  </div>
                  <button @click.prevent="vali" class="btn btn-primary align-self-center ml-5 text-nowrap enable" v-if="!this.scan">Enable</button>
                  <button @click.prevent="validis" class="btn btn-danger align-self-center ml-5 text-nowrap enable" v-else>Disable</button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </Layout>
</template>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
<style lang="scss">
  .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
      border: 1px solid red !important;
    }
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>